import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { Form, FormActions } from "../../../organisms";
import { useNavigate } from "react-router-dom";
import { InputField } from "../../../atoms";

//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  setIsLoading_login,
  setEmail,
  setPassword,
  setUserInfo,
  setRole,
} from "../../../../redux/reducer/auth";
import { setNotification } from "../../../../redux/reducer/commonReducer";
//API
import { auth } from "../../../../utils/api/apiCall";
const StyledForm = styled(Box)({
  width: "100%",
});

export const LoginTemplate = () => {
  const navigate = useNavigate();

  //State
  const { isLoading, email, password } = useSelector(
    (state) => state.loginDetails
  );
  const dispatch = useDispatch();

  const handleEmailChange = (event) => {
    dispatch(setEmail(event.target.value));
  };

  const handlePasswordChange = (event) => {
    dispatch(setPassword(event.target.value));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(setIsLoading_login(true));

    let data = {
      email,
      password,
    };

    let result = await auth.login(data);
    if (result.success) {
      dispatch(setIsLoading_login(false));
      dispatch(setUserInfo(result.user));
      dispatch(setRole(result.user.role));
      dispatch(setEmail(""));
      dispatch(setPassword(""));
      dispatch(
        setNotification({
          visible: true,
          message: result.message,
          statue: "success",
        })
      );
      localStorage.setItem("token", result.token);
      if(result.user.role === "admin"){
        navigate("/postcreate")
      }else{
        navigate("/fetchexpensive") 
      }
    } else {
      dispatch(setIsLoading_login(false));
    }
  };

  return (
    <Form label={"Login"}>
      <StyledForm component="form" onSubmit={handleSubmit}>
        <InputField
          placeholder={"Email*"}
          label={"Email"}
          name={"email"}
          type={"email"}
          required={true}
          value={email}
          onChange={handleEmailChange}
        />
        <InputField
          variant="outlined"
          margin="normal"
          name="password"
          label="Password"
          type="password"
          required={true}
          value={password}
          onChange={handlePasswordChange}
        />
        <FormActions type={"submit"} label={"Login"} loading={isLoading}>
          <Box
            onClick={() => {
              navigate("/register");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            Create an account
          </Box>
        </FormActions>
      </StyledForm>
    </Form>
  );
};
