import axios from "axios";

// let BASE_URL = "http://localhost:5002";
let BASE_URL = "https://api.krince.in";

export const instance = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    config.headers.authorization = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response) {
      const { data } = error.response;
      return data;
    } else {
      return Promise.reject(error);
    }
  }
);
