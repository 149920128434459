import React from "react";
import {
  Box,
  styled,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  FormGroup,
} from "@mui/material";
import { Form, FormActions } from "../../../organisms";
import { InputField } from "../../../atoms";
import { setBody } from "../../../../redux/reducer/expensive";
import { useDispatch, useSelector } from "react-redux";
import { expensive } from "../../../../utils/api/apiCall";

const StyledForm = styled(Box)({
  width: "100%",
});

const Container = styled("div")(({ theme }) => ({
  padding: theme.spacing(3),
  maxWidth: "600px",
  margin: "auto",
}));

export const ExpensiveTemplate = () => {
  const { body } = useSelector((state) => state.exexpenviseDetails);
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const postDataResult = await expensive.createExpensive(body);
      if (postDataResult.success) {
        alert("done");
      }
    } catch (error) {
      console.log(await error);
    }
  };

  const handleChange = (event) => {
    let data = body;
    let value = event.target.value;
    let name = event.target.name;
    data = { ...data, [name]: value };
    dispatch(setBody(data));
  };
  return (
    <Container>
      <Form label={"Create Expenvise"} sx>
        <StyledForm component="form" onSubmit={handleSubmit}>
          <InputField
            placeholder={"Amount*"}
            label={"Amount"}
            name={"amount"}
            type={"number"}
            margin="normal"
            onChange={handleChange}
            required={true}
          />
          <InputField
            placeholder={"Title*"}
            label={"Title"}
            name={"title"}
            type={"text"}
            margin="normal"
            onChange={handleChange}
            required={true}
          />
          <InputField
            placeholder={"Date*"}
            name={"date"}
            type={"date"}
            margin="normal"
            onChange={handleChange}
            required={true}
          />

          <FormGroup>
            <FormControl fullWidth margin="normal">
              <InputLabel id="demo-simple-select-label">Transaction Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Transaction Type"
                name="transaction_type"
                onChange={handleChange}
                required={true}
              >
                <MenuItem value="debit">Debit</MenuItem>
                <MenuItem value="credit">Credit</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>

          {/* //////// */}
          <InputField
            placeholder={"Time*"}
            name={"time"}
            type={"time"}
            margin="normal"
            onChange={handleChange}
          />
          <InputField
            placeholder={"Where*"}
            label={"Where"}
            name={"where"}
            type={"text"}
            margin="normal"
            onChange={handleChange}
          />
          <InputField
            placeholder={"Description*"}
            label={"Description"}
            name={"description"}
            type={"text"}
            margin="normal"
            onChange={handleChange}
          />
          <InputField
            placeholder={"Transaction By*"}
            label={"Transaction By"}
            name={"transaction_by"}
            type={"text"}
            margin="normal"
            onChange={handleChange}
          />
          {/* //////// */}
          <FormActions type={"submit"} label={"Creat"} />
        </StyledForm>
      </Form>
    </Container>
  );
};
