import React from "react";
import { Footer, Navbar } from "../../../organisms";
import {  ScrollOnTop } from "../../../wrapper";
import { FetchExpensiveTemplate } from "../../../Template";

export const FetchExpensive = () => {
  return (
    <ScrollOnTop>
      <FetchExpensiveTemplate />
      <Footer />
    </ScrollOnTop>
  );
};
