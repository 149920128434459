import { combineReducers } from "@reduxjs/toolkit";
import {
  productDetailsReducer,
  cartReducer,
  homeReducer,
  genreReducer,
  orderReducer,
} from "./main";
import { loginReducer, userInfoReducer } from "./auth";
import { commonReducer } from "./commonReducer";
import { allPostReducer } from "./admin";
import { expenviseReducer } from "./expensive";

export const reducers = combineReducers({
  commonDetails: commonReducer,
  homePageDetails: homeReducer,
  productDetails: productDetailsReducer,
  cartDetails: cartReducer,
  loginDetails: loginReducer,
  userDetails: userInfoReducer,
  allPostForAdmin: allPostReducer,
  genreDetails: genreReducer,
  orderDetails: orderReducer,
  exexpenviseDetails: expenviseReducer,
});
