import React, { useEffect, useState, memo } from "react";
import { Box, styled, Typography } from "@mui/material";
import { expensive } from "../../../../utils/api/apiCall";

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const RecordItem = ({ title, subtitle, amount, transactionType }) => (
  <RecordItemWrapper>
    <Box>
      <Typography variant="subtitle2" fontWeight={600}>
        {title}
      </Typography>
      {subtitle && <Typography fontSize={12}>{subtitle}</Typography>}
    </Box>
    <Typography
      variant="h6"
      fontWeight={700}
      color={transactionType == "debit" ? "red" : "green"}
    >
      {amount}
    </Typography>
  </RecordItemWrapper>
);

const CardContainer = memo(({ title, subtitle, children }) => (
  <CardContainerWrapper>
    <CardHeader>
      <Typography variant="h5">{title}</Typography>
      <Typography variant="subtitle2">{subtitle}</Typography>
    </CardHeader>
    {children}
  </CardContainerWrapper>
));

const TransactionIndicator = memo(({ amount, type }) => (
  <IndicatorWrapper color={type === "credit" ? "green" : "red"}>
    <Typography color="#FFFFFF" fontSize={12}>
      {type === "credit" ? "Income" : "Expense"}
    </Typography>
    <TransactionAmount>
      {type === "credit" ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
      {amount}
    </TransactionAmount>
  </IndicatorWrapper>
));

const AllRecord = memo(({ onClick }) => (
  <AllRecordText onClick={onClick}>All record</AllRecordText>
));

export const FetchExpensiveTemplate = () => {
  const [date, setDate] = useState("");
  const [expenseData, setExpenseData] = useState([]);
  const [credit, setCredit] = useState("");
  const [debit, setDebit] = useState("");

  const fetchExpensive = async () => {
    let response = await expensive.fetchExpensive();
    let report = response.result.report;
    console.log(report);
    setDate(report.todayDate);
    setExpenseData(report.data);
    setCredit(report.creditData);
    setDebit(report.debitData);
  };

  useEffect(() => {
    fetchExpensive();
  }, []);

  return (
    <PageContainer>
      <MainContentWrapper>
        <MainCardWrapper>
          <TransactionIndicatorContainer>
            <TransactionIndicator amount={credit} type={"credit"} />
            <TransactionIndicator amount={debit} type={"debit"} />
          </TransactionIndicatorContainer>

          <CardContainer title={"Today record"} subtitle={date}>
            {expenseData.map((item) => (
              <RecordItem
                title={item.title}
                amount={item.amount}
                transactionType={item.transaction_type}
                subtitle={item.transaction_by}
              />
            ))}
            <AllRecord onClick={() => {}} />
          </CardContainer>
        </MainCardWrapper>
      </MainContentWrapper>
    </PageContainer>
  );
};
const PageContainer = styled(Box)({
  backgroundColor: "#FFFFFF",
  height: "100vh",
  fontFamily: "monospace",
});

const MainContentWrapper = styled(Box)(({ theme }) => ({
  width: "50%",
  height: "99%",
  margin: "0px auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: theme.breakpoints.down("sm") ? "10px" : "40px",
  boxSizing: "border-box",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const MainCardWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: "#252525",
  width: "100%",
  height: "99%",
  margin: "auto",
  borderRadius: "20px",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  padding: theme.breakpoints.down("sm") ? "10px" : "30px",
  boxSizing: "border-box",
}));

const CardContainerWrapper = styled(Box)(({ theme }) => ({
  margin: "10px",
  backgroundColor: "#FFFFFF",
  width: "100%",
  borderRadius: "10px",
  padding: "10px 20px",
  boxSizing: "border-box",
}));

const CardHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid #252525",
  marginBottom: "10px",
}));

const RecordItemWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "10px 0px",
}));

const TransactionIndicatorContainer = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  borderRadius: "10px",
  boxSizing: "border-box",
});

const IndicatorWrapper = styled(Box)(({ color }) => ({
  width: "40%",
  padding: "5px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  fontWeight: 600,
  fontSize: 12,
  color: color,
  borderRadius: "5px",
  boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
  backgroundColor: "#252525",
}));

const TransactionAmount = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const AllRecordText = styled(Typography)({
  variant: "subtitle2",
  textAlign: "end",
  cursor: "pointer",
  textDecoration: "underline",
  fontSize: 12,
});
