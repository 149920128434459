import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    isLoading: false,
    body: {
      amount: "",
      transaction_type: "",
      time: "",
      where: "",
      description: "",
      transaction_by: "",
      date: "",
    },
  },
  reducers: {
    setIsLoading_expensive: (state, { payload }) => {
      state.isLoading = payload;
    },
    setBody: (state, { payload }) => {
      state.body = payload;
    },
  },
});

export const { setBody, setIsLoading_expensive } = cartSlice.actions;

export const expenviseReducer = cartSlice.reducer;
