import React from "react";
import { Footer, Navbar } from "../../../organisms";
import {  ScrollOnTop } from "../../../wrapper";
import { ExpensiveTemplate } from "../../../Template";

export const Expensive = () => {
  return (
    <ScrollOnTop>
      <Navbar />
      <ExpensiveTemplate />
      <Footer />
    </ScrollOnTop>
  );
};
